<template>
    <Layout :footer-style="1">
        <!-- Start Slider Area -->
        <div class="slider-area slider-style-1 variation-default height-400 bg_image"
             data-black-overlay="7"
             :style="{'background-image': `url(${require(`@/assets/images/banner/privacy.png`)})`}">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="inner">
                            <h1 class="title" style="font-size: 36px;" data-aos="fade-up" data-aos-delay="150">主动、精准发现系统存在的安全风险</h1>
                            <h1 class="title" style="font-size: 36px;" data-aos="fade-up" data-aos-delay="150">实时发现入侵事件，提供快速防御和响应</h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Slider Area -->
        <div class="container">
          <Breadcrumb current-url="安全隐私"/>
        </div>

        <!-- Start Service Area -->
        <div class="rn-service-area rn-section-gapBottom pt--100 area-2">
            <div class="container">
              <div class="row row--15 service-wrapper">

                <div class="col-lg-4 col-md-6 col-sm-6 col-12">
                  <div class="service text-center service__style--1 radius mt--25`"
                       data-aos="fade-up"
                       data-aos-delay="70">
                    <div class="icon">
                      <img class="p-img" src="@/assets/images/new/u18.png" alt="桌面安全"/>
                    </div>
                    <div class="content">
                      <h4 class="title w-600 mt--50">桌面安全</h4>
                      <p>
                        <router-link to="/security-privacy/desktop-security" class="more">
                          <span>了解更多</span>
                          <img class="i1" src="@/assets/images/home/u45.png">
                          <img class="i2" src="@/assets/images/home/u45.png">
                        </router-link>
                      </p>
                    </div>
                  </div>
                </div>

                <div class="col-lg-4 col-md-6 col-sm-6 col-12">
                  <div class="service text-center service__style--1 radius mt--25`"
                       data-aos="fade-up"
                       data-aos-delay="70">
                    <div class="icon">
                      <img class="p-img" src="@/assets/images/new/u4.png" alt="堡垒机"/>
                    </div>
                    <div class="content">
                      <h4 class="title w-600 mt--50">堡垒机</h4>
                      <p>
                        <router-link to="/security-privacy/jump-server" class="more">
                          <span>了解更多</span>
                          <img class="i1" src="@/assets/images/home/u45.png">
                          <img class="i2" src="@/assets/images/home/u45.png">
                        </router-link>
                      </p>
                    </div>
                  </div>
                </div>

                <div class="col-lg-4 col-md-6 col-sm-6 col-12">
                  <div class="service text-center service__style--1 radius mt--25`"
                       data-aos="fade-up"
                       data-aos-delay="70">
                    <div class="icon">
                      <img class="p-img" src="@/assets/images/new/u10.png" alt="态势感知"/>
                    </div>
                    <div class="content">
                      <h4 class="title w-600 mt--50">态势感知</h4>
                      <p>
                        <router-link to="/security-privacy/situational-awareness" class="more">
                          <span>了解更多</span>
                          <img class="i1" src="@/assets/images/home/u45.png">
                          <img class="i2" src="@/assets/images/home/u45.png">
                        </router-link>
                      </p>
                    </div>
                  </div>
                </div>

                <div class="col-lg-4 col-md-6 col-sm-6 col-12">
                  <div class="service text-center service__style--1 radius mt--25`"
                       data-aos="fade-up"
                       data-aos-delay="70">
                    <div class="icon">
                      <img class="p-img" src="@/assets/images/new/u13.png" alt="威胁情报"/>
                    </div>
                    <div class="content">
                      <h4 class="title w-600 mt--50">威胁情报</h4>
                      <p>
                        <router-link to="/security-privacy/threat-intelligence" class="more">
                          <span>了解更多</span>
                          <img class="i1" src="@/assets/images/home/u45.png">
                          <img class="i2" src="@/assets/images/home/u45.png">
                        </router-link>
                      </p>
                    </div>
                  </div>
                </div>

                <div class="col-lg-4 col-md-6 col-sm-6 col-12">
                  <div class="service text-center service__style--1 radius mt--25`"
                       data-aos="fade-up"
                       data-aos-delay="70">
                    <div class="icon">
                      <img class="p-img" src="@/assets/images/new/u9.png" alt="零信任"/>
                    </div>
                    <div class="content">
                      <h4 class="title w-600 mt--50">零信任</h4>
                      <p>
                        <router-link to="/security-privacy/zero-trust" class="more">
                          <span>了解更多</span>
                          <img class="i1" src="@/assets/images/home/u45.png">
                          <img class="i2" src="@/assets/images/home/u45.png">
                        </router-link>
                      </p>
                    </div>
                  </div>
                </div>

                <div class="col-lg-4 col-md-6 col-sm-6 col-12">
                  <div class="service text-center service__style--1 radius mt--25`"
                       data-aos="fade-up"
                       data-aos-delay="70">
                    <div class="icon">
                      <img class="p-img" src="@/assets/images/new/u16.png" alt="云桌面"/>
                    </div>
                    <div class="content">
                      <h4 class="title w-600 mt--50">云桌面</h4>
                      <p>
                        <router-link to="/security-privacy/cloud-desktop" class="more">
                          <span>了解更多</span>
                          <img class="i1" src="@/assets/images/home/u45.png">
                          <img class="i2" src="@/assets/images/home/u45.png">
                        </router-link>
                      </p>
                    </div>
                  </div>
                </div>

              </div>
            </div>
        </div>
        <!-- End Service Area -->
    </Layout>
</template>

<script>
    import Layout from '../components/common/Layout'
    import Icon from '../components/icon/Icon'
    import Separator from '../components/elements/separator/Separator'
    import SectionTitle from '../components/elements/sectionTitle/SectionTitle'
    import Breadcrumb from '../components/elements/breadcrumb/Breadcrumb'
    export default {
        name: 'Product',
        components: {
            SectionTitle,
            Separator,
            Icon,
            Breadcrumb,
            Layout
        },
        data() {
            return {
            }
        }
    }
</script>
<style lang="scss" scoped>
.display-block{
  display: block;
}
.area-2{
  .icon {
    .p-img {
      width: 64px;
      height: 64px;
    }
  }
  .more{
    display: inline-flex;
    color: #2261AA;
    img {
      width: 27px;
      height: 27px;
    }
    .i1{
      margin-left: -4px;
    }
    .i2{
      margin-left: -20px;
    }
  }
}
</style>
