<template>
  <Layout :footer-style="1">
    <div class="container">
      <Breadcrumb root-url="/software-service" parent-url="软件服务" current-url="安全杀毒" style="border: none;"/>
    </div>

    <div class="rn-service-area rn-section-gapBottom pt--100 bg-color-light area-2">
      <div class="container">
        <div class="product">
          <img class="pic" src="@/assets/images/product/7.png" />
          <div class="about">
            <div class="title">安全杀毒</div>
            <div class="content">
              杀毒软件，也称反病毒软件或防毒软件，是用于消除电脑病毒、特洛伊木马和恶意软件等计算机威胁的一类软件。
              <br><br>
              杀毒软件通常集成监控识别、病毒扫描和清除、自动升级、主动防御等功能，有的杀毒软件还带有数据恢复、防范黑客入侵、网络流量控制等功能，是计算机防御系统(包含杀毒软件，防火墙，特洛伊木马和恶意软件的查杀程序，入侵预防系统等)的重要组成部分。
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Start Service Area -->
    <div class="rn-service-area rn-section-gapBottom pt--60 area-2">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="section-title" data-aos="fade-up">
              <h3 class="title color-gray">卡巴斯基</h3>
            </div>
          </div>
          <div class="col-lg-12 text-center">
            <img class="company-logo" src="@/assets/images/brand/brand-03.png">
            <div class="company-info">
              卡巴斯基反病毒软件是世界上拥有最尖端科技的杀毒软件之一，总部设在俄罗斯首都莫斯科，全名“卡巴斯基实验室”，是国际著名的信息安全领导厂商之一。            </div>
          </div>
        </div>

          <Separator style="margin: 80px 0;"/>

          <div class="row">
              <div class="col-lg-12">
                  <div class="section-title" data-aos="fade-up">
                      <h3 class="title color-gray">ESET</h3>
                  </div>
              </div>
              <div class="col-lg-12 text-center">
                  <img class="company-logo" src="@/assets/images/brand/brand-44.png">
                  <div class="company-info">
                      ESET公司研发的杀毒防毒软件NOD32，是该公司的旗舰产品，它拥有TreatSense高级启发式引擎，具有占用资源小、扫描速度快、监控灵敏、低误报、少用户干预、自我保护好、运行稳定，可有效防范未知病毒于未然等特点。NOD32引领业界启发式判断的最高技术，屡获业界殊荣和赞誉，畅销百余个国家和地区。ESET公司连续五年被评为“德勤高科技快速成长500强”（Deloitte’s Technology Fast 500）公司，拥有广泛的合作伙伴网络，包括佳能、戴尔、微软等国际知名公司，ESET 在全球超过80个国家都设有办公室，在布拉迪斯拉发（斯洛伐克）、伦敦（英国）、布里斯托尔（英国）、布宜诺斯艾利斯（阿根廷）、布拉格（捷克）、圣地亚哥（美国）等地均设有办事处，代理机构覆盖全球超过100个国家。
                  </div>
              </div>
          </div>

      </div>
    </div>
    <!-- End Service Area -->
  </Layout>
</template>

<script>
import Layout from '@/components/common/Layout'
import Icon from '@/components/icon/Icon'
import Separator from '@/components/elements/separator/Separator'
import SectionTitle from '@/components/elements/sectionTitle/SectionTitle'
import Breadcrumb from '@/components/elements/breadcrumb/Breadcrumb'
export default {
  name: 'Product',
  components: {
    SectionTitle,
    Separator,
    Icon,
    Breadcrumb,
    Layout
  },
  data() {
    return {
    }
  }
}
</script>
<style lang="scss" scoped>
.display-block{
  display: block;
}
.area-2{
  .icon {
    .p-img {
      width: 64px;
      height: 64px;
    }
  }
  .more{
    display: inline-flex;
    color: #2261AA;
    img {
      width: 27px;
      height: 27px;
    }
    .i1{
      margin-left: -4px;
    }
    .i2{
      margin-left: -20px;
    }
  }
}
.product{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  .pic{
    height: 220px;
  }
  .about{
    margin-left: 40px;
    color: #000000;
    .title{
      font-size: 32px;
      font-weight: bold;
    }
    .content{
      margin-top: 12px;
      font-size: 20px;
    }
  }
}
.company-logo{
  width: 320px;
}
.company-info{
  font-size: 24px;
  color: #000000;
  text-align: left;
  margin-top: 20px;
}
</style>
