<template>
    <Layout :footer-style="1">
        <!-- Start Slider Area -->
        <div class="slider-area slider-style-1 variation-default height-400 bg_image"
             data-black-overlay="7"
             :style="{'background-image': `url(${require(`@/assets/images/banner/hardware.png`)})`}">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="inner">
                            <h1 class="title" data-aos="fade-up" data-aos-delay="150">AI时代，</h1>
                            <h1 class="title" data-aos="fade-up" data-aos-delay="150">为企业信息化、</h1>
                            <h1 class="title" data-aos="fade-up" data-aos-delay="150">高性能计算提供优异的服务</h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Slider Area -->
        <div class="container">
          <Breadcrumb current-url="硬件产品"/>
        </div>

        <!-- Start Service Area -->
        <div class="rn-service-area rn-section-gapBottom pt--100 area-2">
            <div class="container">
              <div class="row row--15 service-wrapper">
                <div class="col-lg-4 col-md-6 col-sm-6 col-12">
                  <div class="service text-center service__style--1 radius mt--25`"
                       data-aos="fade-up"
                       data-aos-delay="70">
                    <div class="icon">
                      <img class="p-img" src="@/assets/images/new/u11.png" alt="网络产品"/>
                    </div>
                    <div class="content">
                      <h4 class="title w-600 mt--50">网络产品</h4>
                      <p>
                        <router-link to="/hardware-product/network-device" class="more">
                          <span>了解更多</span>
                          <img class="i1" src="@/assets/images/home/u45.png">
                          <img class="i2" src="@/assets/images/home/u45.png">
                        </router-link>
                      </p>
                    </div>
                  </div>
                </div>

                <div class="col-lg-4 col-md-6 col-sm-6 col-12">
                  <div class="service text-center service__style--1 radius mt--25`"
                       data-aos="fade-up"
                       data-aos-delay="70">
                    <div class="icon">
                      <img class="p-img" src="@/assets/images/new/u7.png" alt="服务器"/>
                    </div>
                    <div class="content">
                      <h4 class="title w-600 mt--50">服务器</h4>
                      <p>
                        <router-link to="/hardware-product/server" class="more">
                          <span>了解更多</span>
                          <img class="i1" src="@/assets/images/home/u45.png">
                          <img class="i2" src="@/assets/images/home/u45.png">
                        </router-link>
                      </p>
                    </div>
                  </div>
                </div>

                <div class="col-lg-4 col-md-6 col-sm-6 col-12">
                  <div class="service text-center service__style--1 radius mt--25`"
                       data-aos="fade-up"
                       data-aos-delay="70">
                    <div class="icon">
                      <img class="p-img" src="@/assets/images/new/u2.png" alt="安防监控"/>
                    </div>
                    <div class="content">
                      <h4 class="title w-600 mt--50">安防监控</h4>
                      <p>
                        <router-link to="/hardware-product/security-monitoring" class="more">
                          <span>了解更多</span>
                          <img class="i1" src="@/assets/images/home/u45.png">
                          <img class="i2" src="@/assets/images/home/u45.png">
                        </router-link>
                      </p>
                    </div>
                  </div>
                </div>

                <div class="col-lg-4 col-md-6 col-sm-6 col-12">
                  <div class="service text-center service__style--1 radius mt--25`"
                       data-aos="fade-up"
                       data-aos-delay="70">
                    <div class="icon">
                      <img class="p-img" src="@/assets/images/new/u5.png" alt="存储"/>
                    </div>
                    <div class="content">
                      <h4 class="title w-600 mt--50">存储</h4>
                      <p>
                        <router-link to="/hardware-product/storage" class="more">
                          <span>了解更多</span>
                          <img class="i1" src="@/assets/images/home/u45.png">
                          <img class="i2" src="@/assets/images/home/u45.png">
                        </router-link>
                      </p>
                    </div>
                  </div>
                </div>

                <div class="col-lg-4 col-md-6 col-sm-6 col-12">
                  <div class="service text-center service__style--1 radius mt--25`"
                       data-aos="fade-up"
                       data-aos-delay="70">
                    <div class="icon">
                      <img class="p-img" src="@/assets/images/new/u8.png" alt="负载均衡"/>
                    </div>
                    <div class="content">
                      <h4 class="title w-600 mt--50">负载均衡</h4>
                      <p>
                        <router-link to="/hardware-product/lvs" class="more">
                          <span>了解更多</span>
                          <img class="i1" src="@/assets/images/home/u45.png">
                          <img class="i2" src="@/assets/images/home/u45.png">
                        </router-link>
                      </p>
                    </div>
                  </div>
                </div>

                <div class="col-lg-4 col-md-6 col-sm-6 col-12">
                  <div class="service text-center service__style--1 radius mt--25`"
                       data-aos="fade-up"
                       data-aos-delay="70">
                    <div class="icon">
                      <img class="p-img" src="@/assets/images/new/u1.png" alt="出口安全"/>
                    </div>
                    <div class="content">
                      <h4 class="title w-600 mt--50">出口安全</h4>
                      <p>
                        <router-link to="/hardware-product/outlet-safety" class="more">
                          <span>了解更多</span>
                          <img class="i1" src="@/assets/images/home/u45.png">
                          <img class="i2" src="@/assets/images/home/u45.png">
                        </router-link>
                      </p>
                    </div>
                  </div>
                </div>

              </div>
            </div>
        </div>
        <!-- End Service Area -->
    </Layout>
</template>

<script>
    import Layout from '../components/common/Layout'
    import Icon from '../components/icon/Icon'
    import Separator from '../components/elements/separator/Separator'
    import SectionTitle from '../components/elements/sectionTitle/SectionTitle'
    import Breadcrumb from '../components/elements/breadcrumb/Breadcrumb'
    export default {
        name: 'Product',
        components: {
            SectionTitle,
            Separator,
            Icon,
            Breadcrumb,
            Layout
        },
        data() {
            return {
            }
        }
    }
</script>
<style lang="scss" scoped>
.display-block{
  display: block;
}
.area-2{
  .icon {
    .p-img {
      width: 64px;
      height: 64px;
    }
  }
  .more{
    display: inline-flex;
    color: #2261AA;
    img {
      width: 27px;
      height: 27px;
    }
    .i1{
      margin-left: -4px;
    }
    .i2{
      margin-left: -20px;
    }
  }
}
.title {
		font-size: 36px;
}
</style>
