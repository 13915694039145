<template>
    <ul class="mainmenu">
        <li><router-link to="/"><span class="item" :class="{'active': isActive[0]}">首页</span></router-link></li>
        <li><router-link to="/hardware-product"><span class="item" :class="{'active': isActive[1]}">硬件产品</span></router-link></li>
        <li><router-link to="/software-service"><span class="item" :class="{'active': isActive[2]}">软件服务</span></router-link></li>
        <li><router-link to="/security-privacy"><span class="item" :class="{'active': isActive[3]}">安全隐私</span></router-link></li>
        <li><router-link to="/corporate"><span class="item" :class="{'active': isActive[4]}">生态合作</span></router-link></li>
        <li><router-link to="/about"><span class="item" :class="{'active': isActive[5]}">关于我们</span></router-link></li>
    </ul>
</template>

<script>
    import Icon from "../../icon/Icon";
    export default {
        name: 'Nav',
        components: {Icon},
        data() {
          return {
            isActive: [false, false, false, false, false]
          }
        },
        mounted: function (){
          this.isActive = [false, false, false, false, false]
          let path = this.$route.path
          if(path === '/'){
            this.isActive[0] = true
          } else if(path.indexOf('/hardware-product') === 0){
            this.isActive[1] = true
          } else if(path.indexOf('/software-service') === 0){
            this.isActive[2] = true
          } else if(path.indexOf('/security-privacy') === 0){
            this.isActive[3] = true
          } else if(path.indexOf('/corporate') === 0){
            this.isActive[4] = true
          } else if(path.indexOf('/about') === 0){
            this.isActive[5] = true
          }
        }
    }
</script>
<style lang="scss" scoped>
.mainmenu{
  .item {
    color: #000000;
    padding: 10px 20px;

    &.active{
      color: #ffffff;
      background-color: #1c3f92;
      padding: 10px 20px;
      border-radius: 20px;
    }
  }
}
:deep(body.active-light-mode .mainmenu-nav .mainmenu li.has-droupdown .submenu li a:hover) {
    background: transparent;
}
.mainmenu-nav .mainmenu li {
    line-height: 36px;
}
</style>
