<template>
    <Layout :footer-style="1">
        <!-- Start Slider Area -->
        <div class="demo-slider-area slider-area bg-transparent slider-style-1 area-1">
            <div class="container">
                <div class="row row--30">
									<div class="col-lg-1"></div>
                  <div class="col-lg-5" style="display: flex;flex-direction: column;justify-content: center;">
                      <div class="area-1-text-1" data-aos="fade-up" data-aos-delay="150">自主创新  精品汇聚</div>
                      <div class="area-1-text-2" data-aos="fade-up" data-aos-delay="150">致力于成为国内领先的</div>
                      <div class="area-1-text-2" data-aos="fade-up" data-aos-delay="150">数字化转型合作伙伴</div>
                  </div>
									<div class="col-lg-2"></div>
                  <div class="col-lg-4">
                    <div>
                      <img style="height: 400px;width: 400px;" src="@/assets/images/banner/index-icon.png" />
                    </div>
                  </div>
                </div>
            </div>
        </div>
        <!-- End Slider Area -->

        <!-- Start Our Demo -->
      <section class="rn-service-area rn-section-gap area-2">
          <div class="container">
            <div class="row row--30">
              <div class="col-lg-12">
                <SectionTitle
                    class="mb--70"
                    text-align="center"
                    title="硬件产品"
                    data-aos="fade-up"
                    data-aos-delay="60"
                />
              </div>
            </div>
            <div class="row row--15 service-wrapper">
              <div class="col-lg-4 col-md-6 col-sm-6 col-12">
                <div class="service text-center service__style--1 radius mt--25`"
                     data-aos="fade-up"
                     data-aos-delay="70">
                  <div class="icon">
                    <img class="p-img" src="@/assets/images/new/u11.png" alt="网络产品"/>
                  </div>
                  <div class="content">
                    <h4 class="title w-600 mt--50">网络产品</h4>
                    <p>
                      <router-link to="/hardware-product/network-device" class="more">
                        <span>了解更多</span>
                        <img class="i1" src="@/assets/images/home/u45.png">
                        <img class="i2" src="@/assets/images/home/u45.png">
                      </router-link>
                    </p>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-md-6 col-sm-6 col-12">
                <div class="service text-center service__style--1 radius mt--25`"
                     data-aos="fade-up"
                     data-aos-delay="70">
                  <div class="icon">
                    <img class="p-img" src="@/assets/images/new/u7.png" alt="服务器"/>
                  </div>
                  <div class="content">
                    <h4 class="title w-600 mt--50">服务器</h4>
                    <p>
                      <router-link to="/hardware-product/server" class="more">
                        <span>了解更多</span>
                        <img class="i1" src="@/assets/images/home/u45.png">
                        <img class="i2" src="@/assets/images/home/u45.png">
                      </router-link>
                    </p>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-md-6 col-sm-6 col-12">
                <div class="service text-center service__style--1 radius mt--25`"
                     data-aos="fade-up"
                     data-aos-delay="70">
                  <div class="icon">
                    <img class="p-img" src="@/assets/images/new/u2.png" alt="安防监控"/>
                  </div>
                  <div class="content">
                    <h4 class="title w-600 mt--50">安防监控</h4>
                    <p>
                      <router-link to="/hardware-product/security-monitoring" class="more">
                        <span>了解更多</span>
                        <img class="i1" src="@/assets/images/home/u45.png">
                        <img class="i2" src="@/assets/images/home/u45.png">
                      </router-link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <!-- End Our Demo -->

      <!-- Start Our Demo -->
      <section class="rn-service-area rn-section-gap area-2 bg-color-light">
        <div class="container">
          <div class="row row--30">
            <div class="col-lg-12">
              <SectionTitle
                  class="mb--70"
                  text-align="center"
                  title="软件服务"
                  data-aos="fade-up"
                  data-aos-delay="60"
              />
            </div>
          </div>
          <div class="row row--15 service-wrapper">
            <div class="col-lg-4 col-md-6 col-sm-6 col-12">
              <div class="service text-center service__style--1 radius mt--25`"
                   data-aos="fade-up"
                   data-aos-delay="70">
                <div class="icon">
                  <img class="p-img" src="@/assets/images/new/u14.png" alt="虚拟机"/>
                </div>
                <div class="content">
                  <h4 class="title w-600 mt--50">虚拟机</h4>
                  <p>
                    <router-link to="/software-service/vm" class="more">
                      <span>了解更多</span>
                      <img class="i1" src="@/assets/images/home/u45.png">
                      <img class="i2" src="@/assets/images/home/u45.png">
                    </router-link>
                  </p>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6 col-12">
              <div class="service text-center service__style--1 radius mt--25`"
                   data-aos="fade-up"
                   data-aos-delay="70">
                <div class="icon">
                  <img class="p-img" src="@/assets/images/new/u12.png" alt="网盘"/>
                </div>
                <div class="content">
                  <h4 class="title w-600 mt--50">网盘</h4>
                  <p>
                    <router-link to="/software-service/cloud-drive" class="more">
                      <span>了解更多</span>
                      <img class="i1" src="@/assets/images/home/u45.png">
                      <img class="i2" src="@/assets/images/home/u45.png">
                    </router-link>
                  </p>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6 col-12">
              <div class="service text-center service__style--1 radius mt--25`"
                   data-aos="fade-up"
                   data-aos-delay="70">
                <div class="icon">
                  <img class="p-img" src="@/assets/images/new/u6.png" alt="反垃圾"/>
                </div>
                <div class="content">
                  <h4 class="title w-600 mt--50">反垃圾</h4>
                  <p>
                    <router-link to="/software-service/anti-spam" class="more">
                      <span>了解更多</span>
                      <img class="i1" src="@/assets/images/home/u45.png">
                      <img class="i2" src="@/assets/images/home/u45.png">
                    </router-link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="rn-service-area rn-section-gap area-2">
        <div class="container">
          <div class="row row--30">
            <div class="col-lg-12">
              <SectionTitle
                  class="mb--70"
                  text-align="center"
                  title="安全隐私"
                  data-aos="fade-up"
                  data-aos-delay="60"
              />
            </div>
          </div>
          <div class="row row--15 service-wrapper">
            <div class="col-lg-4 col-md-6 col-sm-6 col-12">
              <div class="service text-center service__style--1 radius mt--25`"
                   data-aos="fade-up"
                   data-aos-delay="70">
                <div class="icon">
                  <img class="p-img" src="@/assets/images/new/u18.png" alt="桌面安全"/>
                </div>
                <div class="content">
                  <h4 class="title w-600 mt--50">桌面安全</h4>
                  <p>
                    <router-link to="/security-privacy/desktop-security" class="more">
                      <span>了解更多</span>
                      <img class="i1" src="@/assets/images/home/u45.png">
                      <img class="i2" src="@/assets/images/home/u45.png">
                    </router-link>
                  </p>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6 col-12">
              <div class="service text-center service__style--1 radius mt--25`"
                   data-aos="fade-up"
                   data-aos-delay="70">
                <div class="icon">
                  <img class="p-img" src="@/assets/images/new/u4.png" alt="堡垒机"/>
                </div>
                <div class="content">
                  <h4 class="title w-600 mt--50">堡垒机</h4>
                  <p>
                    <router-link to="/security-privacy/jump-server" class="more">
                      <span>了解更多</span>
                      <img class="i1" src="@/assets/images/home/u45.png">
                      <img class="i2" src="@/assets/images/home/u45.png">
                    </router-link>
                  </p>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6 col-12">
              <div class="service text-center service__style--1 radius mt--25`"
                   data-aos="fade-up"
                   data-aos-delay="70">
                <div class="icon">
                  <img class="p-img" src="@/assets/images/new/u10.png" alt="态势感知"/>
                </div>
                <div class="content">
                  <h4 class="title w-600 mt--50">态势感知</h4>
                  <p>
                    <router-link to="/security-privacy/situational-awareness" class="more">
                      <span>了解更多</span>
                      <img class="i1" src="@/assets/images/home/u45.png">
                      <img class="i2" src="@/assets/images/home/u45.png">
                    </router-link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

        <section class="rn-service-area rn-section-gap area-2 bg-color-light">
            <div class="container">
                <div class="row row--30">
                    <div class="col-lg-12">
                        <SectionTitle
                            class="mb--40"
                            text-align="center"
                            title="荣誉资质"
                            data-aos="fade-up"
                            data-aos-delay="60"
                        />
                    </div>
                    <honor />
                </div>
            </div>
        </section>

      <section class="rn-service-area rn-section-gap area-2">
        <div class="container">
          <div class="row row--30">
            <div class="col-lg-12">
              <SectionTitle
                  class="mb--70"
                  text-align="center"
                  title="关于我们"
                  data-aos="fade-up"
                  data-aos-delay="60"
              />
            </div>
            <div class="col-lg-7 col-md-7 col-sm-12 about">
              <div class="content mb--20" data-aos="fade-up" data-aos-delay="150">
                品质创造价值，以最大可能为客户做到最好！我们深信，别人做不到的，我们能做到；别人做得到的，我们将会做得更好。中炘科技以云计算、大数据技术为支撑，以产品化、平台化为重点，布局产业互联网，促进跨界融合，构建产业服务新生态，以专业的服务和负责任的态度为用户提供整合的IT服务。
              </div>
              <p data-aos="fade-up" data-aos-delay="150">
                <router-link to="/about" class="more">
                  <span>了解更多</span>
                  <img class="i1" src="@/assets/images/home/u45.png">
                  <img class="i2" src="@/assets/images/home/u45.png">
                </router-link>
              </p>
            </div>
            <div class="col-lg-5 col-md-5 col-sm-12">
              <div class="row text-center" data-aos="fade-up" data-aos-delay="150">
                <div class="col-sm-6">
                  <div class="icon">
                    <img class="p-img" src="@/assets/images/home/u121.png" alt="专业团队"/>
                  </div>
                  <div class="content mt--20">
                    <h5>专业团队</h5>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="icon">
                    <img class="p-img" src="@/assets/images/home/u123.png" alt="技术领先"/>
                  </div>
                  <div class="content mt--20">
                    <h5>技术领先</h5>
                  </div>
                </div>
                <div class="col-sm-6 mt--40">
                  <div class="icon">
                    <img class="p-img" src="@/assets/images/home/u125.png" alt="服务优质"/>
                  </div>
                  <div class="content mt--20">
                    <h5>服务优质</h5>
                  </div>
                </div>
                <div class="col-sm-6 mt--40">
                  <div class="icon">
                    <img class="p-img" src="@/assets/images/home/u127.png" alt="需求定制"/>
                  </div>
                  <div class="content mt--20">
                    <h5>需求定制</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
</template>

<script>
    import Layout from '../components/common/Layout'
    import Icon from '../components/icon/Icon'
    import Separator from '../components/elements/separator/Separator'
    import SectionTitle from '../components/elements/sectionTitle/SectionTitle'
    import ServiceOne from "@/components/elements/service/ServiceOne.vue";
    import Honor from "@/views/honor/index.vue";

    export default {
        name: 'Home',
        components: {Honor, ServiceOne, Icon, Layout, Separator, SectionTitle},
    }
</script>
<style lang="scss" scoped>
.area-1{
  background-image: url("../assets/images/banner/index.png");
  height: 400px;
  .container{
    height: 400px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    .area-1-text-1{
      font-size: 36px;
      font-weight: bold;
      color: #000000;
      width: 360px;
      text-align: center;
      margin-bottom: 20px;
    }
    .area-1-text-2{
      font-size: 24px;
      color: #000000;
      font-weight: 400;
      width: 360px;
      text-align: center;
    }
  }
}
.area-2{
  .icon {
    .p-img {
      width: 64px;
      height: 64px;
    }
  }
  .more{
    display: inline-flex;
    color: #2261AA;
    img {
      width: 27px;
      height: 27px;
    }
    .i1{
      margin-left: -4px;
    }
    .i2{
      margin-left: -20px;
    }
  }
}
.about{
  display: flex;
  justify-content: center;
  flex-direction: column;
  .content{
    color: #000000;
    font-size: 20px;
  }
}
</style>
