<template>
    <Layout :footer-style="1">

        <section class="rn-service-area rn-section-gap area-2 height-400">
        <div class="container">
          <div class="row row--30">
            <div class="col-lg-12 mb--10">
              <div class="section-title text-center" data-aos="fade-up">
                <h3 class="title">关于我们</h3>
              </div>
            </div>
            <div class="col-lg-8 col-md-8 col-sm-12 about">
              <div class="content mb--20" data-aos="fade-up" data-aos-delay="150">
                品质创造价值，以最大可能为客户做到最好！我们深信，别人做不到的，我们能做到；别人做得到的，我们将会做得更好。中炘科技以云计算、大数据技术为支撑，以产品化、平台化为重点，布局产业互联网，促进跨界融合，构建产业服务新生态，以专业的服务和负责任的态度为用户提供整合的IT服务。
              </div>
            </div>
							<div class="col-lg-1"></div>
            <div class="col-lg-3 col-md-3 col-sm-12">
              <div class="row text-center" data-aos="fade-up" data-aos-delay="150">
                <div class="col-sm-5">
                  <div class="icon">
                    <img class="p-img" src="@/assets/images/home/u121.png" alt="专业团队"/>
                  </div>
                  <div class="content mt--10">
                    <h5>专业团队</h5>
                  </div>
                </div>
                <div class="col-sm-5">
                  <div class="icon">
                    <img class="p-img" src="@/assets/images/home/u123.png" alt="技术领先"/>
                  </div>
                  <div class="content mt--10">
                    <h5>技术领先</h5>
                  </div>
                </div>
									<div class="col-sm-2"></div>
                <div class="col-sm-5 mt--20">
                  <div class="icon">
                    <img class="p-img" src="@/assets/images/home/u125.png" alt="服务优质"/>
                  </div>
                  <div class="content mt--10">
                    <h5>服务优质</h5>
                  </div>
                </div>
                <div class="col-sm-5 mt--20">
                  <div class="icon">
                    <img class="p-img" src="@/assets/images/home/u127.png" alt="需求定制"/>
                  </div>
                  <div class="content mt--10">
                    <h5>需求定制</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

        <section class="rn-service-area rn-section-gap area-2 bg-color-light">
            <div class="container">
                <div class="row row--30">
                    <div class="col-lg-12 mb--60">
                        <div class="section-title text-center" data-aos="fade-up">
                            <h3 class="title">荣誉资质
                            </h3>
                        </div>
                    </div>
                    <honor />
                </div>
            </div>
        </section>
        <div class="rwt-brand-area pb--110 pt--110">
        <div class="container">
          <div class="row">
            <div class="col-lg-12 mb--60">
              <div class="section-title text-center" data-aos="fade-up">
                <h3 class="title">联系我们</h3>
              </div>
            </div>
            <div class="col-lg-12">
              <div class="rn-contact-address mt_dec--30">
                <div class="row">
                  <div class="col-lg-4 col-md-6 col-12" data-aos="fade-up" data-aos-delay="150">
                    <div class="rn-address">
                      <div class="icon">
                        <Icon name="headphones" :size="40"/>
                      </div>
                      <div class="inner">
                        <h4 class="title">客服热线</h4>
                        <p><a href="tel:010-56928939">010-56928939</a></p>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-4 col-md-6 col-12" data-aos="fade-up" data-aos-delay="150">
                    <div class="rn-address">
                      <div class="icon">
                        <Icon name="mail" :size="40"/>
                      </div>
                      <div class="inner">
                        <h4 class="title">邮箱</h4>
                        <p>技术部：<a href="mailto:service@cheright.com">service@cheright.com</a></p>
                        <p>人事部：<a href="mailto:public@cheright.com">public@cheright.com</a></p>
                        <p>销售部：<a href="mailto:sales@cheright.com">sales@cheright.com</a></p>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-4 col-md-6 col-12" data-aos="fade-up" data-aos-delay="150">
                    <div class="rn-address">
                      <div class="icon">
                        <Icon name="map-pin" :size="40"/>
                      </div>
                      <div class="inner">
                        <h4 class="title">地址</h4>
                        <p>北京市海淀区硅谷亮城B座713室</p>
                        <p>邮编：100085</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
        <!-- End Elements Area -->
    </Layout>
</template>

<script>
    import Layout from '../components/common/Layout'
    import Icon from "@/components/icon/Icon.vue";
    import Honor from "@/views/honor/index.vue";

    export default {
        name: 'About',
        components: {Honor, Icon, Layout},
        data() {
            return {
            }
        }
    }
</script>
<style lang="scss" scoped>
.area-2{
  .icon {
    .p-img {
      width: 32px;
      height: 32px;
    }
  }
  .more{
    display: inline-flex;
    color: #2261AA;
    img {
      width: 27px;
      height: 27px;
    }
    .i1{
      margin-left: -4px;
    }
    .i2{
      margin-left: -20px;
    }
  }
}
.about{
  display: flex;
  justify-content: center;
  flex-direction: column;
  .content{
    color: #000000;
    font-size: 18px;
  }
}
.rn-address{
  height: 320px;
}
</style>
