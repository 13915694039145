import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Product from '../views/Product.vue'
import Software from '../views/Software.vue'
import Corporate from '../views/Corporate.vue'
import About from '../views/About.vue'
import MailSystem from '../views/software/Mail-System.vue'
import Security from '../views/software/Security.vue'

import Privacy from '../views/Privacy.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: {
            title: '首页',
        },
    },
    {
        path: '/hardware-product',
        name: 'Product',
        component: Product,
        meta: {
            title: '硬件产品',
        }
    },
    {
        path: '/hardware-product/network-device',
        name: "NetworkDevice",
        component: () => import('@/views/product/network-device.vue'),
        meta: {
            title: '网络产品',
        },
    },
    {
        path: '/hardware-product/server',
        name: "NetworkDevice",
        component: () => import('@/views/product/server.vue'),
        meta: {
            title: '服务器',
        },
    },
    {
        path: '/hardware-product/security-monitoring',
        name: "NetworkDevice",
        component: () => import('@/views/product/security-monitoring.vue'),
        meta: {
            title: '安全监控',
        },
    },
    {
        path: '/hardware-product/storage',
        name: "NetworkDevice",
        component: () => import('@/views/product/storage.vue'),
        meta: {
            title: '存储',
        },
    },
    {
        path: '/hardware-product/lvs',
        name: "NetworkDevice",
        component: () => import('@/views/product/lvs.vue'),
        meta: {
            title: '负载均衡',
        },
    },
    {
        path: '/hardware-product/outlet-safety',
        name: "NetworkDevice",
        component: () => import('@/views/product/outlet-safety.vue'),
        meta: {
            title: '出口安全设备',
        },
    },
    {
        path: '/software-service',
        name: 'Software',
        component: Software,
        meta: {
            title: '软件服务',
        },
    },
    {
        path: '/software-service/vm',
        name: 'FireWall',
        component: () => import('@/views/software/vm.vue'),
        meta: {
            title: '虚拟机',
        },
    },
    {
        path: '/software-service/cloud-drive',
        name: 'FireWall',
        component: () => import('@/views/software/cloud-drive.vue'),
        meta: {
            title: '网盘',
        },
    },
    {
        path: '/software-service/anti-spam',
        name: 'FireWall',
        component: () => import('@/views/software/anti-spam.vue'),
        meta: {
            title: '反垃圾',
        },
    },
    {
        path: '/software-service/mail-system',
        name: 'MailSystem',
        component: MailSystem,
        meta: {
            title: '邮件系统',
        },
    },
    {
        path: '/software-service/security',
        name: 'Security',
        component: Security,
        meta: {
            title: '安全杀毒',
        },
    },
    {
        path: '/software-service/disaster-backup',
        name: 'FireWall',
        component: () => import('@/views/software/disaster-backup.vue'),
        meta: {
            title: '灾备',
        },
    },
    {
        path: '/security-privacy',
        name: 'SecurityPrivacy',
        component: Privacy,
        meta: {
            title: '安全隐私',
        },
    },
    {
        path: '/security-privacy/desktop-security',
        name: 'SecurityPrivacy',
        component: () => import('@/views/privacy/desktop-security.vue'),
        meta: {
            title: '桌面安全',
        },
    },
    {
        path: '/security-privacy/jump-server',
        name: 'SecurityPrivacy',
        component: () => import('@/views/privacy/jump-server.vue'),
        meta: {
            title: '堡垒机',
        },
    },
    {
        path: '/security-privacy/situational-awareness',
        name: 'SecurityPrivacy',
        component: () => import('@/views/privacy/situational-awareness.vue'),
        meta: {
            title: '势态感知',
        },
    },
    {
        path: '/security-privacy/threat-intelligence',
        name: 'SecurityPrivacy',
        component: () => import('@/views/privacy/threat-intelligence.vue'),
        meta: {
            title: '威胁情报',
        },
    },
    {
        path: '/security-privacy/cloud-desktop',
        name: 'SecurityPrivacy',
        component: () => import('@/views/privacy/cloud-desktop.vue'),
        meta: {
            title: '云桌面',
        },
    },
    {
        path: '/security-privacy/zero-trust',
        name: 'SecurityPrivacy',
        component: () => import('@/views/privacy/zero-trust.vue'),
        meta: {
            title: '零信任',
        },
    },

    {
        path: '/corporate',
        name: 'Corporate',
        component: Corporate,
        meta: {
            title: '生态合作',
        },
    },
    {
        path: '/about',
        name: 'About',
        component: About,
        meta: {
            title: '关于我们',
        },
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    // document.title = to.meta.title;
    next();
    window.scrollTo(0, 0)
});

export default router
