<template>
    <Layout :footer-style="1">
        <!-- Start Slider Area -->
        <div class="slider-area slider-style-1 variation-default height-400 bg_image"
             data-black-overlay="7"
             :style="{'background-image': `url(${require(`@/assets/images/banner/software.png`)})`}">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="inner">
                            <h1 class="title" style="font-size: 36px;" data-aos="fade-up" data-aos-delay="150">以客户为中心<br>提一系列安全解决方案</h1>
                            <h3 class="title mt--40" style="font-size: 24px;" data-aos="fade-up" data-aos-delay="150">为用户提供从标准硬件系统<br>定制化开发到云数据服务产品一体化解决方案</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Slider Area -->
        <div class="container">
          <Breadcrumb current-url="软件服务"/>
        </div>

        <!-- Start Service Area -->
        <div class="rn-service-area rn-section-gapBottom pt--100 area-2">
            <div class="container">
              <div class="row row--15 service-wrapper">
                <div class="col-lg-4 col-md-6 col-sm-6 col-12">
                  <div class="service text-center service__style--1 radius mt--25`"
                       data-aos="fade-up"
                       data-aos-delay="70">
                    <div class="icon">
                      <img class="p-img" src="@/assets/images/new/u14.png" alt="虚拟机"/>/>
                    </div>
                    <div class="content">
                      <h4 class="title w-600 mt--50">虚拟机</h4>
                      <p>
                        <router-link to="/software-service/vm" class="more">
                          <span>了解更多</span>
                          <img class="i1" src="@/assets/images/home/u45.png">
                          <img class="i2" src="@/assets/images/home/u45.png">
                        </router-link>
                      </p>
                    </div>
                  </div>
                </div>

                <div class="col-lg-4 col-md-6 col-sm-6 col-12">
                  <div class="service text-center service__style--1 radius mt--25`"
                       data-aos="fade-up"
                       data-aos-delay="70">
                    <div class="icon">
                      <img class="p-img" src="@/assets/images/new/u12.png" alt="网盘"/>
                    </div>
                    <div class="content">
                      <h4 class="title w-600 mt--50">网盘</h4>
                      <p>
                        <router-link to="software-service/cloud-drive" class="more">
                          <span>了解更多</span>
                          <img class="i1" src="@/assets/images/home/u45.png">
                          <img class="i2" src="@/assets/images/home/u45.png">
                        </router-link>
                      </p>
                    </div>
                  </div>
                </div>

                <div class="col-lg-4 col-md-6 col-sm-6 col-12">
                  <div class="service text-center service__style--1 radius mt--25`"
                       data-aos="fade-up"
                       data-aos-delay="70">
                    <div class="icon">
                      <img class="p-img" src="@/assets/images/new/u6.png" alt="反垃圾"/>
                    </div>
                    <div class="content">
                      <h4 class="title w-600 mt--50">反垃圾</h4>
                      <p>
                        <router-link to="/software-service/anti-spam" class="more">
                          <span>了解更多</span>
                          <img class="i1" src="@/assets/images/home/u45.png">
                          <img class="i2" src="@/assets/images/home/u45.png">
                        </router-link>
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6 col-12">
                  <div class="service text-center service__style--1 radius mt--25`"
                       data-aos="fade-up"
                       data-aos-delay="70">
                    <div class="icon">
                      <img class="p-img" src="@/assets/images/new/u15.png" alt="邮件系统"/>
                    </div>
                    <div class="content">
                      <h4 class="title w-600 mt--50">邮件系统</h4>
                      <p>
                        <router-link to="/software-service/mail-system" class="more">
                          <span>了解更多</span>
                          <img class="i1" src="@/assets/images/home/u45.png">
                          <img class="i2" src="@/assets/images/home/u45.png">
                        </router-link>
                      </p>
                    </div>
                  </div>
                </div>

                <div class="col-lg-4 col-md-6 col-sm-6 col-12">
                  <div class="service text-center service__style--1 radius mt--25`"
                       data-aos="fade-up"
                       data-aos-delay="70">
                    <div class="icon">
                      <img class="p-img" src="@/assets/images/new/u3.png" alt="安全杀毒"/>
                    </div>
                    <div class="content">
                      <h4 class="title w-600 mt--50">安全杀毒</h4>
                      <p>
                        <router-link to="/software-service/security" class="more">
                          <span>了解更多</span>
                          <img class="i1" src="@/assets/images/home/u45.png">
                          <img class="i2" src="@/assets/images/home/u45.png">
                        </router-link>
                      </p>
                    </div>
                  </div>
                </div>

                <div class="col-lg-4 col-md-6 col-sm-6 col-12">
                  <div class="service text-center service__style--1 radius mt--25`"
                       data-aos="fade-up"
                       data-aos-delay="70">
                    <div class="icon">
                      <img class="p-img" src="@/assets/images/new/u17.png" alt="灾备"/>
                    </div>
                    <div class="content">
                      <h4 class="title w-600 mt--50">灾备</h4>
                      <p>
                        <router-link to="/software-service/disaster-backup" class="more">
                          <span>了解更多</span>
                          <img class="i1" src="@/assets/images/home/u45.png">
                          <img class="i2" src="@/assets/images/home/u45.png">
                        </router-link>
                      </p>
                    </div>
                  </div>
                </div>

              </div>
            </div>
        </div>
        <!-- End Service Area -->
    </Layout>
</template>

<script>
    import Layout from '../components/common/Layout'
    import Icon from '../components/icon/Icon'
    import Separator from '../components/elements/separator/Separator'
    import SectionTitle from '../components/elements/sectionTitle/SectionTitle'
    import Breadcrumb from '../components/elements/breadcrumb/Breadcrumb'
    export default {
        name: 'Software',
        components: {
            SectionTitle,
            Separator,
            Icon,
            Breadcrumb,
            Layout
        },
        data() {
            return {
            }
        }
    }
</script>
<style lang="scss" scoped>
.display-block{
  display: block;
}
.area-2{
  .icon {
    .p-img {
      width: 64px;
      height: 64px;
    }
  }
  .more{
    display: inline-flex;
    color: #2261AA;
    img {
      width: 27px;
      height: 27px;
    }
    .i1{
      margin-left: -4px;
    }
    .i2{
      margin-left: -20px;
    }
  }
}
</style>
