<template>
    <div class="logo">
        <router-link :to="url">
            <img class="logo-light" :src="logo" alt="中炘科技"/>
            <img class="logo-dark" :src="logo" alt="中炘科技"/>
        </router-link>
    </div>
</template>

<script>
    export default {
        name: 'Logo',
        props: {
            url: {
                type: String,
                default: '/'
            },
            logo: {
                type: String,
                default: function () {
                    return require(`@/assets/images/logo/logo.png`)
                }
            }
        }
    }
</script>
<style lang="scss">
.logo-name{
  font-size: 24px;
  font-weight: bold;
  color: #1C3F92;
  margin-left: 16px;
}
</style>
