<template>
    <Layout>

				<!-- Start Service Area -->
				<div class="rn-service-area height-400">
						<div class="container">
								<div class="row">
										<div class="col-lg-12">
												<div class="section-title text-center" data-aos="fade-up">
														<h3 class="title">合作共赢</h3>
												</div>
										</div>
								</div>
								<div class="row">
										<div class="col-lg-12">
												<div class="partner" data-aos="fade-up" data-aos-delay="150">
														<img class="pic" src="@/assets/images/corporate/u73.png">
														<div class="title">技术领先</div>
														<div class="content">中炘科技以分销业务为基石，与国内外数百家领先的ICT及数字科技公司建立紧密的合作关系，将先进的技术理念和产品方案输送到各行各业。</div>
												</div>
												<div class="partner" data-aos="fade-up" data-aos-delay="150">
														<img class="pic" src="@/assets/images/corporate/u75.png">
														<div class="title">服务优质</div>
														<div class="content">中炘科技核心团队由一线互联网多年运维服务部门骨干构成，技术扎实，擅长国内互联网企业运维服务，更能周全考虑业务实际需要。</div>
												</div>
												<div class="partner" data-aos="fade-up" data-aos-delay="150">
														<img class="pic" src="@/assets/images/corporate/u77.png">
														<div class="title">需求定制</div>
														<div class="content">未来，中炘科技还将持续开展产业生态合作创新，推进解决方案开发、产品集成定制、行业标准制定、培训认证等方面的深度合作。</div>
												</div>
										</div>
								</div>
						</div>
				</div>
				<!-- End Service Area -->
        <!-- Start Slider Area
        <div class="demo-slider-area slider-area slider-style-1 area-1">
            <div class="container">
                <div class="row">
                    <div class="col-lg-7" style="display: flex;flex-direction: column;justify-content: center;">
                        <div class="area-1-text-2" data-aos="fade-up" data-aos-delay="150">中炘科技以分销业务为基石，与国内外数百家领先的ICT及数字科技公司建立紧密的合作关系，将先进的技术理念和产品方案输送到各行各业。</div>
                        <div class="area-1-text-2" data-aos="fade-up" data-aos-delay="150">中炘科技还在持续开展产业生态合作创新，推进解决方案开发、产品集成定制、行业标准制定、培训认证等方面的深度合作。</div>
                    </div>
                    <div class="col-lg-5">
                        <div class="corporate">
                            <img src="@/assets/images/banner/co.png" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
       End Slider Area -->

        <div class="rn-service-area pb--60 pt--60 bg-color-light">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="section-title text-center" data-aos="fade-up">
                            <h3 class="title">资质授权</h3>
                        </div>
                    </div>
                </div>
                <div class="row pt--40">
                    <div class="col-lg-3 col-md-3 col-3 mb--30"
                         v-for="(gallery, index) in galleryData"
                         :key="index">
                        <div class="co-gallery radius-small icon-center" data-aos="fade-up">
                            <div class="thumbnail">
                                <img :src="gallery.src" alt=""/>
                            </div>
														<div class="video-icon">
															<Button variant-button-class="rounded-player btn-border"
																			:is-link="false"
																			@btnClick="imageIndex = index">
																<div style="text-align: center;width: 37px;height: 40px;line-height: 36px;"><Icon name="zoom-in" size="22"/></div>
															</Button>
														</div>
                        </div>
                    </div>
                </div>
            </div>
					<CoolLightBox
						:items="galleryData"
						:index="imageIndex"
						@close="imageIndex = null">
					</CoolLightBox>
        </div>

        <!-- Start Brand Area -->
        <div class="rwt-brand-area pb--60 pt--60">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="section-title text-center" data-aos="fade-up">
                            <h3 class="title">生态伙伴</h3>
                        </div>
                    </div>
                </div>
                <div class="row pt--40">
                    <div class="col-lg-12">
                        <Brand :brand-list="brandList2" :brand-style="2"/>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>

<script>
    import Layout from '../components/common/Layout'
    import Brand from '../components/elements/brand/Brand'
		import Icon from "@/components/icon/Icon.vue";
		import Button from "@/components/button/Button.vue";

    export default {
        name: 'Company',
        components: {Icon, Brand, Layout, Button},
        data() {
            return {
								imageIndex: null,
                brandList2: [
                    {
                        image: require(`@/assets/images/brand/brand-01.png`)
                    },
                    {
                        image: require(`@/assets/images/brand/brand-02.png`)
                    },
                    {
                        image: require(`@/assets/images/brand/brand-03.png`)
                    },
                    {
                        image: require(`@/assets/images/brand/brand-04.png`)
                    },
                    {
                        image: require(`@/assets/images/brand/brand-05.png`)
                    },
                    {
                        image: require(`@/assets/images/brand/brand-06.png`)
                    },
                    {
                        image: require(`@/assets/images/brand/brand-07.png`)
                    },
                    {
                        image: require(`@/assets/images/brand/brand-08.png`)
                    },
                    {
                        image: require(`@/assets/images/brand/brand-09.png`)
                    },
                    {
                        image: require(`@/assets/images/brand/brand-10.png`)
                    },
                    {
                        image: require(`@/assets/images/brand/brand-11.png`)
                    },
                    {
                        image: require(`@/assets/images/brand/brand-12.png`)
                    },
                    {
                        image: require(`@/assets/images/brand/brand-13.png`)
                    }
                ],
                galleryData: [
                    {
                        src: require(`@/assets/images/corporate/new/aruba.jpg`)
                    },
                    {
											src: require(`@/assets/images/corporate/new/eagleyun.png`)
                    },
                    {
											src: require(`@/assets/images/corporate/new/eset.png`)
                    },
                    {
											src: require(`@/assets/images/corporate/new/h3c.jpg`)
                    },
										{
											src: require(`@/assets/images/corporate/new/infosec.png`)
										},
                    {
											src: require(`@/assets/images/corporate/new/maildata.jpg`)
                    },
                    {
											src: require(`@/assets/images/corporate/new/sangfor.png`)
                    },
                    {
											src: require(`@/assets/images/corporate/new/softnext.jpg`)
                    },
                    {
											src: require(`@/assets/images/corporate/new/superhexa.png`)
                    },
                    {
											src: require(`@/assets/images/corporate/new/threatbook.png`)
                    },
                    {
											src: require(`@/assets/images/corporate/new/zte.png`)
                    },
									{
										src: require(`@/assets/images/corporate/new/volcengine.jpg`)
									},
									{
										src: require(`@/assets/images/corporate/new/baidu.jpg`)
									},
									{
										src: require(`@/assets/images/corporate/new/ali.png`)
									},
                ]
            }
        }
    }
</script>
<style lang="scss" scoped>
.partner{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 80px;
  margin-bottom: 20px;
  .pic{
    width: 40px;
    height: 40px;
  }
  .title {
    font-size: 24px;
    font-weight: bold;
    width: 150px;
    text-align: center;
  }
  .content{
    width: calc(100% - 350px);
    font-size: 18px;
    color: #000000;
  }
}
.company{
  display: flex;
  justify-content: center;
  flex-direction: row;
  .foot-logo{
    width: 200px;
  }
  .about-us{
    font-size: 20px;
    margin-left: 24px;
    margin-top: 28px;
  }
}
.area-1{
    background-color: rgb(37, 122, 189);
    height: 330px;
    .container{
        height: 330px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        .area-1-text-2{
            font-size: 20px;
            color: #ffffff;
            font-weight: 600;
            width: 720px;
            margin-top: 20px;
        }
        .corporate {
            text-align: center;
            img {
                width: 180px;
            }
        }
    }
}
.co-gallery {
    height: 322px;
    line-height: 282px;
    background-color: #ffffff;
    text-align: center;
    padding: 20px;
    margin: 0 8px;
	  position: relative;
	.video-icon {
		opacity: 0;
		transition: 0.4s;
		transform: translate(-50%, -50%) scale(0.5);
		position: absolute;
		content: "";
		left: 50%;
		top: 50%;
		margin-top: 10px;
	}
	&:hover .video-icon {
		opacity: 1;
		margin-top: 0;
		transform: translate(-50%, -50%) scale(1);
	}
    img {
        max-height: 282px;
    }
}
</style>
