<template>
    <div>
        <!-- Start Footer Area  -->
        <footer v-if="data.showFooter" class="rn-footer footer-style-default variation-two">
            <div v-if="data.showCta" class="rn-callto-action clltoaction-style-default style-7">
                <div class="container">
                    <div class="company">
                      <img class="foot-logo" src="@/assets/images/home/company.png" alt="Corporate Logo"/>
                      <div class="about-us">
                        北京中炘信息技术有限公司秉承“信誉第一、诚信合作、用户至上、科技领先” 的理念为用户提供性能出众的信息化集成解决方案。公司是从事计算机软硬件销售、服务及维护、系统集成、软件开发于一体的高科技企业。中炘科技以计算、大数据技术为支撑，以产品化、平台化为重点，布局产业互联网，促进跨界融合，构建产业服务新生态，以专业的服务和负责任的态度为用户提供整合的IT服务。
                      </div>
                    </div>
                </div>
            </div>
            <div class="footer-top">
              <div class="container something">
                <div>
                  地址：北京市海淀区硅谷亮城2B座713
                </div>
                <div>
                  邮政编码：100085
                </div>
                <div>
                  客服热线：010-56928939
                </div>
                <div>
                  客服邮箱：service@cheright.com
                </div>
              </div>
            </div>
        </footer>
        <!-- End Footer Area  -->

        <Copyright v-if="data.showCopyright"/>

        <ScrollTop/>
    </div>
</template>

<script>
    import Copyright from './Copyright'
    import Button from '../../elements/button/Button'
    import Icon from '../../icon/Icon'
    import ScrollTop from './ScrollTop'
    import Logo from '../../elements/logo/Logo'
    import Newsletter from './Newsletter'

    export default {
        name: 'Footer',
        components: {Newsletter, Logo, ScrollTop, Icon, Button, Copyright},
        props: {
            data: {
                default: null
            }
        }
    }
</script>
<style lang="scss" scoped>
.company{
  padding-top: 80px;
  display: flex;
  justify-content: center;
  flex-direction: row;
  .foot-logo{
    width: 200px;
  }
  .about-us{
    margin-left: 24px;
    margin-top: 28px;
  }
}
.something{
  display: flex;
  div {
    flex: 1 1 0;
  }
}
</style>
